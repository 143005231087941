import React from "react"
import PropTypes from "prop-types"
import { Provider } from "react-redux"
import Layout from "../components/Layout/Layout"
import createStore from "../redux/store"
import JobListing from "../components/JobListing/JobListing"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"

const JobListingPage = props => {
  const { pageContext } = props
  const { seoData, globalComponents, pageType, dataLayer } = pageContext
  // GTM custom dataLayer Object based on environment
  if (typeof window !== "undefined") {
    window.$ = window.jquery = window.jQuery = require("jquery")
    const dataLayerObj = {
      ...dataLayer,
      page: {
        url: window.location.href,
        title: seoData?.title || seoData?.metaTitle,
      },
      content: {
        type: pageType || "others",
        category: "",
      },
    }
    window.gtmDataLayer = dataLayerObj
  }

  const store = createStore()
  return (
    <Layout seoData={seoData} globalComponents={globalComponents}>
      <Provider store={store}>
        <JobListing {...props.pageContext} />
      </Provider>
    </Layout>
  )
}

export const Head = () => {
  const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
  return (
    <>
      <script
        id="first-unique-id"
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
        }}
      />
      <script
        async
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
      />
      <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.4/jquery.min.js" />
      <script src="https://cdn.jsdelivr.net/npm/js-cookie@3.0.1/dist/js.cookie.min.js"></script>
    </>
  )
}

JobListingPage.propTypes = {
  pageContext: PropTypes.shape({
    seoData: PropTypes.object,
    globalComponents: PropTypes.array,
    dataLayer: PropTypes.object,
    pageType: PropTypes.string,
  }),
}

export default JobListingPage
